import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTA from "../../../general/cta"

const Investments = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section">
                <div className="kuda-section--inner general-style">
                    <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                        <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
                            <div className="align-self-center mr-2">
                                <div className="f-14">
                                    Legal
                                </div>
                            </div>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 mr-2 text-left">Nigeria</span>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 text-bold text-left">Investment Products</span>
                        </Link>
                        <div className="legal-left">
                            <h1 className="kuda-section--heading mb-0 text-lg-biz text-xlbold color-primary title-bottom--spacing no-margins">
                                Investment Products - DISCLAIMER
                            </h1>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            Arrangement with BAMBOO
                        </h1>
                        <p className="color-black text-bold bottom--spacing">
                            DISCLAIMER
                        </p>
                        <p className="color-black bottom--spacing">
                            <span className="text-bold">IMPORTANT:</span> {" "}You must read the following disclaimer carefully before opening an account with Bamboo Systems Technology Limited (“Bamboo”) and purchasing or dealing in any shares or other form of securities or investment products (“Products”) using Bamboo’s platform. The Kuda application and its contents are confidential and should not be distributed, published or reproduced (in whole or in part) or disclosed by recipients to any other person.
                        </p>
                        <p className="color-black bottom--spacing">
                            Nothing in this electronic transmission constitutes an advertisement of, or offer to buy or sell, the Products by Kuda Technologies Limited or Kuda Microfinance Bank Limited (together “Kuda”). The Products are being offered and sold by Bamboo. [Bamboo has confirmed to Kuda that it has obtained all regulatory permits and licences required to offer the Products on its platform].
                        </p>
                        <p className="color-black bottom--spacing">
                            The terms and conditions governing the purchase of the Products are as set out by Bamboo on its platform. Please read Bamboo’s terms and conditions thoroughly before you proceed to purchase any Product. Any information regarding the Products which can be accessed through Kuda’s application is not specifically tailored to your individual investment objectives, financial situation, characteristics or needs, and is not intended to be a substitute for professional advice. Please note that Kuda has not conducted any due diligence or investigation with respect to such information. You are, therefore, advised to seek professional legal, tax, investment and financial advice before purchasing any Product from Bamboo using your Kuda account.
                        </p>
                        <p className="color-black bottom--spacing">
                            <span className="text-bold">Payment for the Products shall be done in Nigerian Naira and following your authorisation, will be paid directly to Bamboo. </span> No representation or warranty, express or implied is, or will be, given by Kuda or its employees, officers or directors or any other person as to the validity or fairness of the price of the Products.
                        </p>
                        <p className="color-black bottom--spacing">
                            You undertake that you will not, in connection with your consideration of the possible purchase the Products, approach or enter into discussions with any director, officer or employee of Kuda. All approaches by you should be directed to Bamboo.
                        </p>
                        <p className="color-black bottom--spacing">
                            There will be no purchase of the Products in any jurisdiction in which such a purchase may be restricted by law. You are, therefore, obligated to inform yourself about and observe any such restrictions. Any failure to comply with these restrictions could result in a violation of the laws of such jurisdiction.
                        </p>
                        <p className="color-black bottom--spacing">
                            Kuda will not be liable (either civil or criminal) for any loss that you may suffer by reason of your purchase of any Product that is being offered by Bamboo through the Kuda app. All recourse available to you shall be as set out in the terms and conditions as issued by Bamboo. In addition, all customer service support in relation to the use of the Products shall be provided to you directly by Bamboo.
                        </p>
                        <p className="color-black bottom--spacing">
                            You acknowledge and agree that no person in Kuda has nor is held out as having any authority to give any statement, warranty, representation, or undertaking on behalf of Kuda in connection with the Products. Kuda shall have no responsibility or liability whatsoever for any errors, omissions, misstatements, negligence or otherwise, relating to the Products.
                        </p>
                        <p className="color-black bottom--spacing">
                            You confirm that you shall not engage in any fraudulent activity or breach any Anti-Money Laundering laws applicable in any jurisdiction, in your dealings with the Products.
                        </p>
                        <p className="color-black bottom--spacing">
                            By processing payment, you confirm that you have read our disclaimer and Bamboo’s terms and conditions thoroughly and have accepted the contents contained therein.
                        </p>
                    </div>
                </div>
            </div>
            <CTA />
        </Fragment>
    )
}

export default Investments
